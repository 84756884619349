<template>
    <div>
        <div class="flex lg:gap-x-4.5 flex-col lg:flex-row" :class="isTextarea ? 'items-start' : 'lg:items-center'">
            <span
                v-if="label"
                class="text-sm font-Source-Sans-Pro-Bold lg:text-right text-grey-900 uppercase mb-2 lg:mb-0 pl-3 lg:pl-0 inline-flex lg:justify-end input-label opacity-80"
                :class="{
                    'lg:w-4/12': narrow || sm,
                    'lg:w-3/12': !narrow || !sm,
                    'input-label--required': required,
                }"
            >
                {{ label }}
            </span>

            <div
                :class="{
                    'w-full lg:w-9/12 lg:max-w-[26.125rem]': label && !narrow && !sm,
                    'w-full': !label,
                    'w-full lg:w-8/12': narrow,
                    'lg:w-4/12': sm,
                }"
            >
                <Dropdown
                    v-if="isDropdown"
                    v-model="computedValue"
                    :options="options"
                    option-label="name"
                    option-value="id"
                />
                <Dropdown
                    v-else-if="isExtendedDropdown"
                    v-model="computedValue"
                    :options="options"
                    option-label="name"
                    option-value="id"
                    data-key="id"
                    :filter="true"
                    :show-clear="true"
                    empty-filter-message="Nincs találat"
                >
                    <template #value="slotProps">
                        <div v-if="slotProps.value" class="dropdown-item whitespace-normal leading-6 pl-3 mr-2">
                            {{ options.find((option) => option.id === slotProps.value).name }}
                        </div>
                        <div v-else class="dropdown-item"></div>
                    </template>
                    <template v-if="false">
                        <div v-if="option.name" :key="option.id" class="dropdown-item">
                            <div>{{ option.name }}</div>
                        </div>
                    </template>
                </Dropdown>
                <Textarea
                    v-else-if="isTextarea"
                    v-model="computedValue"
                    rows="12"
                    cols="30"
                    :placeholder="placeholder"
                    class="rounded-3xl"
                />
                <InputText v-else v-model="computedValue" :placeholder="placeholder" />
            </div>
        </div>
        <InputError
            v-show="isErrorVisible"
            :class="{
                'w-full lg:w-9/12 lg:max-w-[26.125rem]': label && !narrow && !sm && !isErrorNarrow,
                'w-full': !label,
                'w-full lg:w-8/12': narrow || sm || isErrorNarrow,
            }"
            class="mt-1 ml-auto pl-4.5 lg:pl-0 lg:mr-4"
            :errors="error"
        />
    </div>
</template>

<script>
export default {
    name: 'Input',
    props: {
        label: {
            type: String,
            required: false,
            default: null,
        },
        isErrorVisible: {
            type: Boolean,
            required: false,
            default: false,
        },
        required: {
            type: Boolean,
            required: false,
            default: true,
        },
        sm: {
            type: Boolean,
            required: false,
            default: false,
        },
        value: {
            type: [String, Number, Object],
            required: false,
            default: null,
        },
        error: {
            type: Array,
            required: false,
            default: () => [],
        },
        isNumber: {
            type: Boolean,
            required: false,
            default: false,
        },
        isDropdown: {
            type: Boolean,
            required: false,
            default: false,
        },
        isExtendedDropdown: {
            type: Boolean,
            required: false,
            default: false,
        },
        isTextarea: {
            type: Boolean,
            required: false,
            default: false,
        },
        narrow: {
            type: Boolean,
            required: false,
            default: false,
        },
        placeholder: {
            type: String,
            required: false,
            default: null,
        },
        isErrorNarrow: {
            type: Boolean,
            required: false,
            default: false,
        },
        options: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    computed: {
        computedValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            },
        },
    },
};
</script>

<style>
.input-label {
    @apply opacity-80;
    &--required {
        &::after {
            content: ' *';
            @apply text-error pl-[1px];
        }
    }
}
</style>
