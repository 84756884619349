import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9a3014da = () => interopDefault(import('../pages/contact-for-schools.vue' /* webpackChunkName: "pages/contact-for-schools" */))
const _63affae3 = () => interopDefault(import('../pages/locker.vue' /* webpackChunkName: "pages/locker" */))
const _526905c4 = () => interopDefault(import('../pages/registration.vue' /* webpackChunkName: "pages/registration" */))
const _27b381e1 = () => interopDefault(import('../pages/unsubscribe-successful.vue' /* webpackChunkName: "pages/unsubscribe-successful" */))
const _6e52eb47 = () => interopDefault(import('../pages/payment-failed/_failed_payment_id.vue' /* webpackChunkName: "pages/payment-failed/_failed_payment_id" */))
const _42aaff4d = () => interopDefault(import('../pages/payment-successful/_successful_payment_id.vue' /* webpackChunkName: "pages/payment-successful/_successful_payment_id" */))
const _6c39c982 = () => interopDefault(import('../pages/registration-successful/_successful_registration_id.vue' /* webpackChunkName: "pages/registration-successful/_successful_registration_id" */))
const _a1be4c5e = () => interopDefault(import('../pages/subscription-renewal/_subscription_renewal_id.vue' /* webpackChunkName: "pages/subscription-renewal/_subscription_renewal_id" */))
const _4c304ed7 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4a79ad8f = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contact-for-schools",
    component: _9a3014da,
    name: "contact-for-schools___hu___default"
  }, {
    path: "/contact-for-schools",
    component: _9a3014da,
    name: "contact-for-schools___hu"
  }, {
    path: "/locker",
    component: _63affae3,
    name: "locker___hu___default"
  }, {
    path: "/locker",
    component: _63affae3,
    name: "locker___hu"
  }, {
    path: "/registration",
    component: _526905c4,
    name: "registration___hu___default"
  }, {
    path: "/registration",
    component: _526905c4,
    name: "registration___hu"
  }, {
    path: "/unsubscribe-successful",
    component: _27b381e1,
    name: "unsubscribe-successful___hu___default"
  }, {
    path: "/unsubscribe-successful",
    component: _27b381e1,
    name: "unsubscribe-successful___hu"
  }, {
    path: "/payment-failed/:failed_payment_id",
    component: _6e52eb47,
    name: "payment-failed-failed_payment_id___hu___default"
  }, {
    path: "/payment-failed/:failed_payment_id",
    component: _6e52eb47,
    name: "payment-failed-failed_payment_id___hu"
  }, {
    path: "/payment-successful/:successful_payment_id",
    component: _42aaff4d,
    name: "payment-successful-successful_payment_id___hu___default"
  }, {
    path: "/payment-successful/:successful_payment_id",
    component: _42aaff4d,
    name: "payment-successful-successful_payment_id___hu"
  }, {
    path: "/registration-successful/:successful_registration_id?",
    component: _6c39c982,
    name: "registration-successful-successful_registration_id___hu___default"
  }, {
    path: "/registration-successful/:successful_registration_id?",
    component: _6c39c982,
    name: "registration-successful-successful_registration_id___hu"
  }, {
    path: "/subscription-renewal/:subscription_renewal_id",
    component: _a1be4c5e,
    name: "subscription-renewal-subscription_renewal_id___hu___default"
  }, {
    path: "/subscription-renewal/:subscription_renewal_id",
    component: _a1be4c5e,
    name: "subscription-renewal-subscription_renewal_id___hu"
  }, {
    path: "/",
    component: _4c304ed7,
    name: "index___hu___default"
  }, {
    path: "/",
    component: _4c304ed7,
    name: "index___hu"
  }, {
    path: "/:slug",
    component: _4a79ad8f,
    name: "slug___hu___default"
  }, {
    path: "/:slug",
    component: _4a79ad8f,
    name: "slug___hu"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
