var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex lg:gap-x-4.5 flex-col lg:flex-row",class:_vm.isTextarea ? 'items-start' : 'lg:items-center'},[(_vm.label)?_c('span',{staticClass:"text-sm font-Source-Sans-Pro-Bold lg:text-right text-grey-900 uppercase mb-2 lg:mb-0 pl-3 lg:pl-0 inline-flex lg:justify-end input-label opacity-80",class:{
                'lg:w-4/12': _vm.narrow || _vm.sm,
                'lg:w-3/12': !_vm.narrow || !_vm.sm,
                'input-label--required': _vm.required,
            }},[_vm._v("\n            "+_vm._s(_vm.label)+"\n        ")]):_vm._e(),_vm._v(" "),_c('div',{class:{
                'w-full lg:w-9/12 lg:max-w-[26.125rem]': _vm.label && !_vm.narrow && !_vm.sm,
                'w-full': !_vm.label,
                'w-full lg:w-8/12': _vm.narrow,
                'lg:w-4/12': _vm.sm,
            }},[(_vm.isDropdown)?_c('Dropdown',{attrs:{"options":_vm.options,"option-label":"name","option-value":"id"},model:{value:(_vm.computedValue),callback:function ($$v) {_vm.computedValue=$$v},expression:"computedValue"}}):(_vm.isExtendedDropdown)?_c('Dropdown',{attrs:{"options":_vm.options,"option-label":"name","option-value":"id","data-key":"id","filter":true,"show-clear":true,"empty-filter-message":"Nincs találat"},scopedSlots:_vm._u([{key:"value",fn:function(slotProps){return [(slotProps.value)?_c('div',{staticClass:"dropdown-item whitespace-normal leading-6 pl-3 mr-2"},[_vm._v("\n                        "+_vm._s(_vm.options.find((option) => option.id === slotProps.value).name)+"\n                    ")]):_c('div',{staticClass:"dropdown-item"})]}}]),model:{value:(_vm.computedValue),callback:function ($$v) {_vm.computedValue=$$v},expression:"computedValue"}},[_vm._v(" "),(false)?[(_vm.option.name)?_c('div',{key:_vm.option.id,staticClass:"dropdown-item"},[_c('div',[_vm._v(_vm._s(_vm.option.name))])]):_vm._e()]:_vm._e()],2):(_vm.isTextarea)?_c('Textarea',{staticClass:"rounded-3xl",attrs:{"rows":"12","cols":"30","placeholder":_vm.placeholder},model:{value:(_vm.computedValue),callback:function ($$v) {_vm.computedValue=$$v},expression:"computedValue"}}):_c('InputText',{attrs:{"placeholder":_vm.placeholder},model:{value:(_vm.computedValue),callback:function ($$v) {_vm.computedValue=$$v},expression:"computedValue"}})],1)]),_vm._v(" "),_c('InputError',{directives:[{name:"show",rawName:"v-show",value:(_vm.isErrorVisible),expression:"isErrorVisible"}],staticClass:"mt-1 ml-auto pl-4.5 lg:pl-0 lg:mr-4",class:{
            'w-full lg:w-9/12 lg:max-w-[26.125rem]': _vm.label && !_vm.narrow && !_vm.sm && !_vm.isErrorNarrow,
            'w-full': !_vm.label,
            'w-full lg:w-8/12': _vm.narrow || _vm.sm || _vm.isErrorNarrow,
        },attrs:{"errors":_vm.error}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }